












































































































import { Component, Vue } from 'vue-property-decorator';
import AuthAlert from '@/shared/components/auth/AuthAlert.vue';
import { UserModule } from '../../store';
import { Role } from '../../utils/permissions/permission.model';
import { UserCreate } from '../../store/modules/user/user.model';
import { DivisionModule } from '@/store/index';

@Component({
  components: {
    AuthAlert
  }
})
export default class CreateUser extends Vue {
  divisionModule = DivisionModule;
  userModule = UserModule;

  d = '0000';
  t = null;

  loading = false;

  dismissCountDown = 0;

  roles = [
    { value: Role.Accountant, text: Role.Accountant },
    { value: Role.Dispatcher, text: Role.Dispatcher },
    { value: Role.Admin, text: Role.Admin },
    { value: Role.SuperAdmin, text: Role.SuperAdmin }
  ];

  form: UserCreate = { email: '', name: '', role: null, phone: '', site: 'UA' };

  async createUser() {
    this.loading = true;
    try {
      await this.userModule.createUser(this.form);
      this.dismissCountDown = 8;
    } catch (e) {
      this.dismissCountDown = 0;
      console.log(e);
    }
    this.loading = false;
  }

  countDownChanged(dismissCountDown: number) {
    this.dismissCountDown = dismissCountDown;
  }

  resetForm() {
    this.form = { email: '', name: '', role: null, phone: '', site: 'UA' };
  }

  change(e) {
    console.log(e);
  }
}
